import React, {useContext, useState} from "react";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {hasWriteAccess} from "../../../util/userControl";
import UserData from "../../../store/User/UserData";
import '../../../styles/pages/Vulnerabilities2/Vulnerabilities/ExpandedViewTable.scss';
import {postUpdateActionOrComment} from "../../../api/ApiClient";
import {captureSentryError} from "../../../util/sentry";
import {Checkbox} from "@mui/material";
import ActionComment from "../ActionComment";
import {actionTooltipConfig, updateAsset} from "../config/vulnerabilitytabconfig";
import {useNavigate} from "react-router-dom";
import {sorting} from "../../../config/mappingData";
import {rowActionOptions} from "../config/vulnerabilitytabconfig";
import { ReactComponent as EditComment } from "../../../assets/icons/note-edit-icon.svg";
import { ReactComponent as AddComment } from "../../../assets/icons/note-add-icon.svg";

export const ExpandedViewTable = ({
                                    headers,
                                    data,
                                    className,
                                    page,
                                    vuln_id,
                                    selectAllCheckboxes,
                                    setSelectAllCheckboxes,
                                    selectedCheckboxes,
                                    setSelectedCheckboxes,
                                    setParentTab,
                                    sortTable,
                                    params
                                  }) => {
  const [userData] = useContext(UserData);
  const [selectedActions, setSelectedActions] = useState(data.map(d => d.action_taken || 'New Risk'));
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openCommentRowIndex, setOpenCommentRowIndex] = useState(null);
  const [comments, setComments] = useState(data.map(d => d?.comment || ''));
  const [loading, setLoading] = useState(false);
  const [existingComment, setExistingComment] = useState(data.map(d => d?.comment || ''));
  const [commentError, setCommentError] = useState(false);
  const navigate = useNavigate();

  const openActionDropdown = (rowIndex, value) => setOpenDropdownIndex(value ? rowIndex : null);

  const selectActionFromDropdown = (rowIndex, value) => {
    setSelectedActions(actions => actions.map((action, index) => index === rowIndex ? value : action));
    openActionDropdown(rowIndex, false);
  };

  const addComment = async (value, rowIndex) => {
    setLoading(true);
    try {
      const asset_name = data[rowIndex]?.hostname || data[rowIndex]?.ipaddress;
      await postUpdateActionOrComment(userData?.tenant, vuln_id, {asset_names: [asset_name], comment: value});
      const updatedComments = comments.map((comment, index) => index === rowIndex ? value : comment);
      setComments(updatedComments);
      const updatedExistingComments = existingComment.map((comment, index) => index === rowIndex ? value : comment);
      setExistingComment(updatedExistingComments);
      data[rowIndex].comment_last_updated = Math.floor((new Date()).getTime() / 1000);
      setOpenCommentRowIndex(null);
    } catch (e) {
      setCommentError(true);
      captureSentryError(e, userData, "postUpdateActionOrComment add comment");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (value) => {
    if (!selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes([...selectedCheckboxes, value])
    } else {
      let filteredList = selectedCheckboxes.filter(item => item !== value)
      setSelectedCheckboxes([...filteredList])
    }
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAllCheckboxes;
    setSelectAllCheckboxes(newSelectAll);
    setSelectedCheckboxes(newSelectAll ? data.map(item => item.hostname || item.ipaddress) : []);
  };

  const handleComments = (value, rowIndex) => {
    const updatedComments = comments.map((comment, index) => index === rowIndex ? value : comment);
    setComments(updatedComments);
  };

  const handleExistingComments = (value, rowIndex) => {
    existingComment[rowIndex] = value;
    setExistingComment([...existingComment]);
  };

  const handleClick = (item) => {
    let host = item.hostname || item.ipaddress
    setParentTab('assets')
    navigate('/risk-management/vulnerabilities2', {state: {host}})
  }

  return (
    <table className={`vulnerabilities-tab-table-container ${page}`}>
      <tbody className="table-body">
      <tr className={`table-row headers ${className}`}>
        {Object.keys(headers).map((key, index) => (
          <th
            key={'table-header-' + index}
            className={key}
            onClick={(e) => e.target.type !== 'checkbox' && sortTable(key)}
          >
            {key === 'action_taken' &&
            <Checkbox
              key={'checkbox-' + index}
              className="custom-checkbox select-all"
              checked={selectAllCheckboxes === true}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectAllChange(e);
              }}
            />}
            {headers[key].toUpperCase().replace('_', ' ')}
            {params.sort_by === key && (params.order === 'asc' ?
              <img className={"asc caret"} src={sorting['Asc']} alt=""/> : params.order === 'desc' ?
                <img className={"desc caret"} src={sorting['Desc']} alt=""/> : null)}
          </th>
        ))}
      </tr>
      {data.map((obj, rowIndex) =>
        <tr className={`table-row ${className}`} key={'table-row-' + rowIndex} onClick={() => handleClick(obj)}>
          {Object.keys(headers).map((item, colIndex) =>
            item === 'action_taken' ?
              <td className="Action td-value" key={'table-cell-' + colIndex} onClick={(e) => e.stopPropagation()}>
                <div className="dropdown-sort dropdown-container">
                  <Checkbox
                    key={item + rowIndex}
                    className="custom-checkbox"
                    checked={selectedCheckboxes.includes(obj.hostname || obj?.ipaddress)}
                    onChange={() => handleCheckboxChange(obj.hostname || obj.ipaddress)}
                  />
                  <Tooltip
                    content={actionTooltipConfig[selectedActions[rowIndex]] || ''}
                    className={`title-tooltip ${openDropdownIndex === rowIndex ? 'show' : ''}`}
                    direction="bottom"
                    type="action-value"
                    disable={openDropdownIndex === rowIndex}
                  >
                    {selectedActions[rowIndex] === 'Risk Mitigated' || !hasWriteAccess(userData?.role) ?
                      <p className="risk-mitigated-title">{selectedActions[rowIndex]}</p>
                      : <Dropdown
                        className="action-dropdown"
                        name={selectedActions[rowIndex]}
                        dropdownList={rowActionOptions}
                        selectItem={(value) =>
                          updateAsset(value, rowIndex, selectedCheckboxes, selectActionFromDropdown, vuln_id, data,
                            userData, setLoading)
                        }
                        selectedItem={selectedActions[rowIndex]}
                        showDropdown={openDropdownIndex === rowIndex}
                        setShowDropdown={(value, e) => {
                          e.stopPropagation();
                          openActionDropdown(rowIndex, value, e)
                        }}
                      />}
                  </Tooltip>
                  {openCommentRowIndex === rowIndex &&
                  <ActionComment
                    submitComment={(value) => addComment(value, rowIndex)}
                    commentValue={comments[rowIndex]}
                    commentLastUpdated={data[rowIndex]?.comment_last_updated}
                    setCurrentComment={(value) => handleComments(value, rowIndex)}
                    close={async () => {
                      setOpenCommentRowIndex(null);
                      handleExistingComments(comments[rowIndex], rowIndex)
                      setCommentError(false);
                    }}
                    index={rowIndex}
                    existingComment={existingComment[rowIndex]}
                    loading={loading}
                    setExistingComment={handleExistingComments}
                    commentError={commentError}
                  />}
                </div>
              </td> : item === 'comment' ?
              <td
                key={'table-cell-' + colIndex}
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenCommentRowIndex(openCommentRowIndex === rowIndex ? null : rowIndex)
                }}
                className={item}
              >
                {(existingComment[rowIndex] !== '' || comments[rowIndex] !== '') ?
                  <EditComment className={"icon edit"} /> : <AddComment className={"icon add"}/>}
              </td>
              : <td className={item} key={'table-cell-' + colIndex}>{obj[item] || ''}</td>
          )}
        </tr>)}
      </tbody>
    </table>
  );
};
